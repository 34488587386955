.li {
    font-size: 16px;
    margin-left: 10px;
    list-style-type: square;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  
  .body {
    display: block;
    /* margin-top: 30px; */
    flex-direction: column;
    font-family: 'ProximaNovaRegular', Arial;
    /* margin-left: 20px; */
  }
  code {
    font-family: 'ProximaNovaRegular', Arial;
  }
  .aboutContainer {
    display: flex;
    flex-direction: column;
    padding: 8px 32px 8px 32px;
  }
  