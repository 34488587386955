.tutorial {
  height: 500px;
}

.tutorial img {
  object-fit: contain;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .tutorial {
    height: auto;
  }

  .tutorial img {
    width: 100%;
    object-fit: contain;
  }
}
