/*
reset default styles
*/
@font-face {
  font-family: 'ProximaNovaRegular';
  src: local('ProximaNovaRegular'), url(./fonts/proxima_nova_regular.woff) format('woff'), 
  url(./fonts/proxima_nova_regular.woff2) format('woff2');
}

@font-face {
  font-family: "ProximaNovaRegular";
  src: url(./fonts/proxima_nova_bold.woff) format('woff'), 
  url(./fonts/proxima_nova_bold.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: "ProximaNovaRegular";
  src: url(./fonts/proxima_nova_thin_italic.woff) format('woff'),  
  url(./fonts/proxima_nova_thin_italic.woff2) format('woff2');
  font-style: italic;
}