.rowContainerCenter {
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .colContainerCenter {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .btn {
    border: 1px solid;
    border-radius: 8px;
    background-color: #EEEEEE;
    border-color: #EEEEEE;
    border-width: 1px;
    padding: 16px;
    cursor: pointer;
  }
  
  .btn:hover{
    background-color: #F8F8F8;
  }
  