.item-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.item-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.item-check:hover input~.checkmark {
  background-color: #666;
}

/* When the checkbox is checked, add a blue background */
.item-check input:checked~.checkmark {
  background-color: #fff;
}

.text {
  font-family: 'ProximaNovaRegular', Arial;
  font-size: 16px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.item-check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.item-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #666;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.item-check input:checked~.item-check {
  text-decoration: underline;

}

.contact {
  max-width: 500px;
  margin: 0 auto;
  
}

.contact textarea {
  outline: 0;
  width: 100%;
  height: 100px;
  /* border: none; */
  /* border-radius: 2px; */
}

.groupBtn {
  margin-top: 20px;
  text-align: right;
}

.submit {
  background: #666;
  padding: 10px 20px;
  color: #fff;
  font-size: 18px;
  text-align: right;
  cursor: pointer;
}

.item {
  width: 100%
}

.list-item>.item {
  flex: 0 50%;
  margin: 10px 0;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.contact p {
  font-size: 20px;
  margin-bottom: 10px;
}

label.checked {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .contact {
    max-width: 100%;
    font-size: 16px;
  }

  .item-check {
    font-size: 16px;
    margin: 20px 0;
  }

  .list-item {
    display: block;
    font-size: 16px;
  }

  .list-item>.item {
    display: block;
  }

  .groupBtn {
    text-align: center;

  }
}
.uk-radio:checked {
  background-color: gray !important;
}
label:has(> .uk-radio:checked) {
  text-decoration: underline;
}