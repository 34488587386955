.Overlay {
  background-color: rgba(101, 104, 107, 0.7) !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* // .modal_content {
//   background-color: #EAEAEA;
//   box-shadow: 0px 6px 5px #65686B80;
//   color: #707070;
//   padding: 1rem;
//   margin: 1rem;
// }

// .ReactModal__Content {
//   outline: none !important;
// }

// .modal_footer {
//   color: #EAEAEA;
//   padding: 1rem;
//   margin: 1rem;
//   margin-top: 0;
//   padding-top: 0;
//   text-align: right;
// } */
.Modal {
  outline: none;
}

@media only screen and (max-width: 600px) {
  .Modal {
    width: 90% !important;
  }
}

.closeBtn {
  padding: 16px;
}

.closeBtn:focus {
  background: transparent;
}
