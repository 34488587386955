@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

svg {
  display: inline;
}

[data-show] {
  display: block !important;
}

.section_row {
  width: calc(50% - 12.5px);
}

.home-item-horizontal {
  min-width: 185px;
  height: 50px;
}

.home-item-vertical {
  height: 100px;
  width: 105px;
}
.home-item-vertical-lg {
  height: 100px;
  width: 130px;
}
.home-item-verticalIT {
  height: 100px;
  width: 125px;
}

.home-item-double .home-item-vertical {
  height: 98px;
}

.home-item-double .home-item-vertical:first-child {
  width: 120px;
}
.home-item-double .home-item-vertical:last-child {
  width: 80px;
}

.home-mobile-collapsible-content {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.home-item-double {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
}

.collapsible-dropdown-content {
  max-width: 440px;
}

.bu-double-indecators {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  top: 100%;
  left: 50%;
  margin-left: -60px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  /* text-align: center; */
  position: absolute;
  z-index: 1;
  background-color: #002544;
  white-space: nowrap;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.content-units.expanded {
  width: 400px;
}

.content-units {
  width: 0;
}

.switcher-button {
  min-width: 150px;
}

.switcher-button svg {
  min-width: 36px;
  min-height: 36px;
}

.bu-graph-section {
  width: 140px;
}

.units-container {
  max-height: 400px;
}

.note {
  max-width: 213px;
}

.OI .main-graph-container,
.NET_SALES .main-graph-container,
.VOLUME .main-graph-container,
.OSHA_RECORDABLES .main-graph-container{
  max-width: calc(100% - 8rem);
}

.main-graph-container {
  max-width: calc(100% - 4rem);
}

.text-align-left {
  text-align: left;
  padding-right: 300px;
}

@media (max-width: 900px) {
  .note {
    max-width: unset;
  }

  .units-container {
    max-height: unset;
  }

  .bu-graph-section {
    width: 90px;
  }

  .big-graph-container {
    min-width: 600px;
  }

  .switcher-button {
    width: unset;
  }

  .content-units {
    width: unset;
  }

  .section_row {
    width: 100%;
  }

  .collapsible-dropdown-content {
    max-width: unset;
  }

  .home-item-horizontal {
    min-width: 185px;
    width: 100%;
    height: 50px;
  }

  .home-item-vertical {
    height: 72px;
    width: unset;
  }

  .home-item-double .home-item-vertical {
    height: 70px;
  }

  .home-item-double .home-item-vertical:first-child {
    width: auto;
  }
  .home-item-double .home-item-vertical:last-child {
    width: auto;
  }
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 0px 0px;
}
.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
